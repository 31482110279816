.smart-gantt-chart .material-icons {
    --smart-font-family-icon: 'Material Icons';
    --smart-font-size: 18px;
    /* -webkit-font-feature-settings: 'liga'; */
    -webkit-font-smoothing: antialiased;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v115/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.task-start-date {
    height: 47px
}

.task-start-date .smart-input {
    font-size: 16px!important;
    color: rgba(0,0,0,0.3);
}

.task-end-date {
    height: 47px
}

.fade-in {

}

.fade-out {
    
}
